<template>
  <div class="registration-confirmed-container thank-you-page">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <v-card flat>
        <v-card-text>
          <CategoryTitle :category="category" class="mb-3" />

          <ResponseMessage :response="response" v-if="!loading" />
          <span v-else>{{ $t("registrationConfirm.loading") }}</span>
        </v-card-text>

        <v-card-actions class="justify-end mt-7" v-if="!loading">
          <v-btn
            v-if="!isAuthenticated"
            large
            depressed
            color="primary"
            min-width="200"
            @click="handleLogin"
            >{{ $t("register.confirmed.doLogin") }}</v-btn
          >
          <v-btn
            v-else
            large
            depressed
            color="primary"
            min-width="200"
            :to="{ name: 'Home' }"
            >{{ $t("register.confirmed.goToHome") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.registration-confirmed-container {
  min-height: 60vh;
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";

import { mapGetters } from "vuex";
import customService from "@/service/customService";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle, ResponseMessage },
  mixins: [categoryMixins, loginMixins],
  data() {
    return {
      loading: true,
      email: null,
      token: null,
      response: {}
    };
  },
  ...mapGetters({
    isAuthenticated: "cart/isAuthenticated"
  }),
  methods: {
    async handleLogin() {
      let isLoggedIn = await this.doLogin("Home");
      if (isLoggedIn) {
        this.$router.push({
          name: "Home"
        });
      }
    },
    async confirmRegistration(token) {
      try {
        this.response = {};
        let res = await customService.confirmRegistration(token);

        if (res && res.status == 0) {
          // this.$router.push({ name: "RegistrationCompleted" });
          this.response = {
            infos: [
              {
                info: this.$t("registrationConfirm.confirmSuccess")
              }
            ]
          };
        } else {
          if (res) {
            this.response = res;
          } else {
            this.response = {
              errors: [
                {
                  error: this.$t("registrationConfirm.genericError")
                }
              ]
            };
          }
        }
      } catch (err) {
        console.log(err);
        this.response = {
          errors: [
            {
              error: this.$t("registrationConfirm.genericError")
            }
          ]
        };
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    console.log("RegistrationConfirmed", this.$route, this.$route.query);
    if (this.$route.query && this.$route.query.token) {
      this.token = this.$route.query.token;
      this.confirmRegistration(this.token);
    }
  }
};
</script>
